body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-image: url("images/dark-honeycomb.png");
}

.title {
  background: #31B3DA;
  margin: 0;
  padding: 20px;
  color: white;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

table {
  color: white;
}